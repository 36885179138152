import React from "react";

import LenderLogoSlider from "components/lender_logos_slider";
import TeamShowcaseSlider2 from "components/team_showcase_slider_2";
import CTABar1 from "components/cta_bar_1";
import ScrollAnimation from "react-animate-on-scroll";
// import KG2Analytics from 'components/kg2_analytics';
import "./styles.css";
import LargeHeroBanner from "../../components/large_hero_banner";

const AboutPage = () => {
  return (
    <>
      {/* KG2 Analytics */}
      {/* <KG2Analytics /> */}

      {/* Header Space */}
      <div id="header-area-space" className="slider-area bg-light-primary" />

      <LargeHeroBanner bgImage={"/img/banner/about-bg.jpg"}>
        <h2>
          <strong>About Us</strong>
        </h2>
        <br />
        <h2>Finstead is the</h2>
        <h2>
          <strong>partner of choice</strong>
        </h2>
        <h2>for Australian businesses</h2>
        <br />
        <a
          href="/contact"
          className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2"
        >
          Request A Call
          <i className="fas fa-angle-right" />
        </a>
      </LargeHeroBanner>

      {/* Video Header */}
      {/* <div className='about-video-container'>
                <video title="About Us Video" className='about-us' autoPlay loop muted>
                    <source src="/vid/gold-glitter-vid.mp4" type="video/mp4" />
                </video>

                <div className='about-us-video-overlay'>
                    <div className="container">
                        <div className="slider-big-text color-light-accent margin-b-5">
                            <h2 className="title title-light body-fw-bold" style={{fontSize: "4.4em"}}>About Us</h2>
                        </div>
                        <br/>
                        <div className="slider-big-text color-light-accent margin-b-5">Finstead <span style={{textTransform: "lowercase"}}>provide a</span></div>
                        <div className="slider-big-text color-ligh-accent margin-b-5"><span className="color-accent body-fw-bold" style={{textTransform: "lowercase"}}>no-fuss simple and</span></div>
                        <div className="slider-big-text color-ligh-accent margin-b-5"><span className="color-accent body-fw-bold" style={{textTransform: "lowercase"}}>efficient process</span></div>
                        <div className="slider-big-text color-ligh-accent margin-b-30"  style={{textTransform: "lowercase"}}>across all our services.</div>
                        <div className="slider-btn-area">
                            <a href="/contact" className="btn-fill color-light radius-4 btn-icon size-sm icon-right">Request A Call<i className="fas fa-angle-right" /></a>
                        </div>

                    </div>
                </div>
            </div> */}

      <CTABar1 />

      <br />

      <div className="mt-5 container">
        <div className="section-heading text-center heading-dark heading-layout1">
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutDown">
            <h2 className="fs-section-header">Why Finstead?</h2>
          </ScrollAnimation>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-3 text-center service-layout5">
            <div style={{ minHeight: "90px", paddingTop: "5px" }}>
              <img srcset="/icons/independent.png 2x" width={"65px"} />
            </div>
            <h3 className="title title-small size-xs color-dark-accent hover-color-accent mt-4 pt-2">
              Independent
            </h3>
            <p>
              We are independent of the banks, we give SMEs better choice and
              transparency
            </p>
          </div>
          <div className="col-md-12 col-lg-3 text-center service-layout5">
            <div style={{ minHeight: "90px", paddingTop: "10px" }}>
              <img srcset="/icons/relationships.png 2x" width={"90px"} />
            </div>
            <h3 className="title title-small size-xs color-dark-accent hover-color-accent mt-4 pt-2">
              Relationships
            </h3>
            <p>
              Knowing our clients and their businesses allow us to deliver
              tailored solutions and build long term relationships
            </p>
          </div>
          <div className="col-md-12 col-lg-3 text-center service-layout5">
            <div style={{ minHeight: "90px" }}>
              <img srcset="/icons/purposeful.png 2x" width={"70px"} />
            </div>
            <h3 className="title title-small size-xs color-dark-accent hover-color-accent mt-4 pt-2">
              Purposeful
            </h3>
            <p>
              We actively seek sustainable value creation for our customers,
              partners and community
            </p>
          </div>
          <div className="col-md-12 col-lg-3 text-center service-layout5">
            <div style={{ minHeight: "90px", paddingTop: "5px" }}>
              <img srcset="/icons/experience.png 2x" width={"60px"} />
            </div>
            <h3 className="title title-small size-xs color-dark-accent hover-color-accent mt-4 pt-2">
              Experienced
            </h3>
            <p>
              Our broad experience across a range of businesses as owners and
              advisors, allows us to deliver superiour client outcomes
            </p>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="container">
        <hr />
      </div>
      <br />
      <br />

      <div className="container mt-4">
        <div className="w-100 justify-desktop">
          <h3 style={{ fontWeight: "300", fontSize: "29px" }}>
            We are committed to listening to your needs and working with you for
            a tailored and timely outcome.
          </h3>
          <br />
        </div>
        <div className="justify-desktop">
          <p>
            We are experienced finance and insurance professionals who
            understand business and more importantly understand the Australian
            credit landscape. Finstead Capital was founded to provide an
            unprecedented level of support and service to provide our customers
            access to the best available finance products in market. We are
            trusted by thousands of businesses every year and with one of
            Australia's most extensive panel of lenders, combined with a high
            performance team, we remain at the ready to solve every day finance
            challenges for your business.
          </p>
          <p>
            We believe in a can-do attitude, priding ourselves in our ability to
            build long term relationships based on trust and outcomes. We create
            exceptional value by working as your trusted partner to support your
            businesses growth.
          </p>
        </div>
      </div>

      <br />

      <br />
      <TeamShowcaseSlider2 />

      {/* Brand Area Start Here */}
      <LenderLogoSlider />

      {/* <div> */}
      {/* About Us Area Start Here */}
      {/* <section className="section-space-less30">
                    <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-8 col-12">
                        <div className="about-layout2 margin-b-30r">
                            <div className="banner-area d-flex justify-content-lg-end bg-light-accent100 bg-overlay overlay-width-80 gradient-vertical overlay-right gradient-transparent-light bg-common md--bg-none" data-bg-image="/img/banner/about1.jpg">
                            <div className="item-content zindex-up-7">
                                <ScrollAnimation animateIn={"fadeInUp"}>
                                    <h2 className="title size-xs title-medium color-dark-accent">Our Company</h2>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn={"fadeInUp"}>
                                    <p>Finstead Capital was founded to provide an unprecedented level of support and service to allow our customers to access the best available finance products in market. </p>
                                </ScrollAnimation>
                            </div>
                            </div>
                            <ScrollAnimation animateIn={"fadeInUp"}>
                            <h2 className="title-medium color-dark-accent size-xs title-bar-lg pseudo-bg-accent100">Who We are</h2>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn={"fadeInUp"}>
                            <p>Finstead is a group of professional brokers who are committed to listening to your needs and working with you for a fast outcome. Our people are experienced finance and insurance professionals who understand business and more importantly understand the Australian credit landscape.</p>
                            </ScrollAnimation>
                            <blockquote cite="#">
                            <ScrollAnimation animateIn={"fadeInRight"}>
                            We were convinced that there’s a better, faster, simpler way and a more sustainable model to finance the needs of business. Whilst not losing sight of the importance of our people, our integrity, the brand and the needs of our partners.
                            </ScrollAnimation>
                            </blockquote>
                            
                        </div>
                        </div>
                        <div className="sidebar-widget-area sidebar-break-md col-xl-3 col-lg-4 col-12">
                        <ScrollAnimation animateIn={"fadeInRight"}>
                        <div className="widget widget-classes">
                            <ul>
                            <li>
                                <a href="/services/asset-finance/">
                                <i className="fas fa-angle-right" />
                                Asset Finance</a>
                            </li>
                            <li>
                                <a href="/services/automotive-finance/">
                                <i className="fas fa-angle-right" />
                                Automotive Finance</a>
                            </li>
                            <li>
                                <a href="/services/business-loans/">
                                <i className="fas fa-angle-right" />
                                Business Loans</a>
                            </li>
                            <li>
                                <a href="/services/commercial-property/">
                                <i className="fas fa-angle-right" />
                                Commercial Property</a>
                            </li>
                            </ul>
                        </div> 
                        <br />
                        </ScrollAnimation>
                        <div className="widget widget-action-layout2 bg-accent100">
                            <h3 className="title title-bold size-sm color-light-accent">Get In Touch
                            <span> - We're available!</span>
                            </h3>
                            <a href="/contact" className="btn-fill bg-light border-color-light radius-4 color-dark size-xs hover-color-accent">Contact Now</a>
                        </div>
                        <ScrollAnimation animateIn={"fadeInRight"}>
                        <TestimonialSliderSmall />
                        </ScrollAnimation>
                        </div>                        
                    </div>
                    </div>
                </section> */}
      {/* <TeamShowcaseSlider /> */}
      {/* About Us Area End Here */}
      {/* Brand Area Start Here */}
      {/* <LenderLogoSlider /> */}
      {/* Brand Area End Here */}
      {/* <CTABar2 />
            </div> */}
    </>
  );
};

export default AboutPage;
