import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./index.css";

const TeamShowcaseSlider2 = () => {
  return (
    <>
      <ScrollAnimation animateIn="slideInUp" animateOnce>
        <div className="team-showcase-slider-circle-top" />
        <img
          src="/img/banner/page-sep-1.png"
          style={{ position: "relative" }}
        ></img>
      </ScrollAnimation>
      <section className="section-space-default-less30 bg-light-accent100">
        <div className="container">
          <div className="section-heading text-center heading-dark heading-layout1">
            <ScrollAnimation animateIn="fadeInRight" animateOnce>
              <h2 className="fs-section-header">The Finstead Team</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
              <p>Meet the expert team at the end of the line</p>
            </ScrollAnimation>
          </div>
          <div
            className="rc-carousel nav-control-middle3"
            data-loop="true"
            data-items={3}
            data-margin={30}
            data-autoplay="true"
            data-autoplay-timeout={5000}
            data-smart-speed={2000}
            data-dots="false"
            data-nav="true"
            data-nav-speed="false"
            data-r-x-small={1}
            data-r-x-small-nav="true"
            data-r-x-small-dots="false"
            data-r-x-medium={2}
            data-r-x-medium-nav="true"
            data-r-x-medium-dots="false"
            data-r-small={3}
            data-r-small-nav="true"
            data-r-small-dots="false"
            data-r-medium={3}
            data-r-medium-nav="true"
            data-r-medium-dots="false"
            data-r-large={3}
            data-r-large-nav="true"
            data-r-large-dots="false"
          >
            <div className="team-layout1 margin-b-30r bg-light-primary100">
              <div className="item-img">
                <img
                  src={`https://cdn.finstead.com.au/profiles/joel.png`}
                  alt="team"
                  className="img-fluid width-100"
                />
                {/* <ul className="item-social">
                    <li>
                      <a href="#" title="Linkedin">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul> */}
              </div>
              <div className="item-content">
                <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                  <a href="#">Joel Togo</a>
                </h3>
                <div className="sub-title title-regular">Senior Associate</div>
              </div>
            </div>

            <div className="team-layout1 margin-b-30r bg-light-primary100">
              <div className="item-img">
                <img
                  src={`https://cdn.finstead.com.au/profiles/steve.png`}
                  alt="team"
                  className="img-fluid width-100"
                />
                {/* <ul className="item-social">
                    <li>
                      <a href="#" title="Linkedin">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul> */}
              </div>
              <div className="item-content">
                <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                  <a href="#">Steven Shirley</a>
                </h3>
                <div className="sub-title title-regular">Director</div>
              </div>
            </div>

            <div className="team-layout1 margin-b-30r bg-light-primary100">
              <div className="item-img">
                <img
                  src={`https://cdn.finstead.com.au/profiles/lucy.png`}
                  alt="team"
                  className="img-fluid width-100"
                />
                {/* <ul className="item-social">
                    <li>
                      <a href="#" title="Linkedin">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul> */}
              </div>
              <div className="item-content">
                <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                  <a href="#">Lucinda Luong</a>
                </h3>
                <div className="sub-title title-regular">Senior Assoicate</div>
              </div>
            </div>

            {/* <div className="team-layout1 margin-b-30r bg-light-primary100">
                <div className="item-img">
                  <img src={`https://cdn.finstead.com.au/profiles/connor_thomson.jpeg`} alt="team" className="img-fluid width-100" />
                  <ul className="item-social">
                    <li>
                      <a href="#" title="Linkedin">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item-content">
                  <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                    <a href="single-service1.html">Connor Thomson</a>
                  </h3>
                  <div className="sub-title title-regular">Assoicate</div>
                </div>
              </div> */}
          </div>
        </div>
      </section>
      <div className="team-showcase-slider-circle-bottom" />
    </>
  );
};

export default TeamShowcaseSlider2;
